// npm
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

interface Props {
  collection: Queries.DatoCmsWebsiteMockupCollection
}

const WebsiteMockupCollection = ({ collection }: Props) => {
  const theme = useTheme()
  const breakpoint = 'md'
  const breakpointDown = useMediaQuery(theme.breakpoints.down(breakpoint))
  const tabletGridProps = {
    xs: 12,
    sm: 6,
  }
  const phoneGridProps = {
    xs: 6,
    sm: 3,
  }
  return (
    <>
      <GatsbyImage
        image={collection.desktopMockup.gatsbyImageData}
        alt={collection.desktopMockup.alt}
      />
      <Grid
        container
        spacing={breakpointDown ? 2 : 5}
        sx={{ mt: { xs: 3, [breakpoint]: 5, xl: 8 } }}
      >
        <Grid {...tabletGridProps}>
          <GatsbyImage
            image={collection.tabletMockup1.gatsbyImageData}
            alt={collection.tabletMockup1.alt}
          />
        </Grid>
        <Grid {...tabletGridProps}>
          <GatsbyImage
            image={collection.tabletMockup2.gatsbyImageData}
            alt={collection.tabletMockup2.alt}
          />
        </Grid>
        <Grid {...phoneGridProps}>
          <GatsbyImage
            image={collection.phoneMockup1.gatsbyImageData}
            alt={collection.phoneMockup1.alt}
          />
        </Grid>
        <Grid {...phoneGridProps}>
          <GatsbyImage
            image={collection.phoneMockup2.gatsbyImageData}
            alt={collection.phoneMockup2.alt}
          />
        </Grid>
        <Grid {...phoneGridProps}>
          <GatsbyImage
            image={collection.phoneMockup3.gatsbyImageData}
            alt={collection.phoneMockup3.alt}
          />
        </Grid>
        <Grid {...phoneGridProps}>
          <GatsbyImage
            image={collection.phoneMockup4.gatsbyImageData}
            alt={collection.phoneMockup4.alt}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default WebsiteMockupCollection
